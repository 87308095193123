import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/layout'
import QuestionnaireResultsListing from '../components/QuestionnaireResultsListing';
import QuoteRow from '../components/QuoteRow';
import WideContainer from '../components/WideContainer/widecontainer';
import ResultsMasthead from '../components/ResultsMasthead';
import { getQuoteRow } from '../services/questionnaire';

const ResultsPage = ({ pageContext }) => {

  const [quoteData, setQuoteData] = useState(null)

  useEffect(() => {
    getQuoteRow().then(res => setQuoteData(res))
  }, [])

  return (
    <Layout>
      <ResultsMasthead {...pageContext.DataMasthead}/>
      <WideContainer>
        <QuestionnaireResultsListing heading={pageContext.ResourcesNotNameProvidedHeader} />
        { quoteData?.quote_rows?.length > 0 && <QuoteRow {...quoteData?.quote_rows[0]}/>}
      </WideContainer>
    </Layout>
  )
}

export default ResultsPage

export { Head } from '../components/_GatsbyPageHead'

export const query = graphql`
  query($NodeId: String!){
    ...QuoteRow
  }
`
