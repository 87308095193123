import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import { handleBackgroundColor, handleFontColor } from '../../utils/colorHandler';
import OrbImage from '../OrbImage';

const ChapterSummary = ({ results }) => {
  return (
    <ChapterContainer>
      <ChapterList>
        {
          results && results.map((el, i) => (
            <ChapterListItem key={i}>
              <ChapterLinkContainer>
                <ChapterIcon>
                  <OrbImage type={el.chapter_theme}/>
                </ChapterIcon>
                <ChapterTextContainer>
                  <ChapterTitle>{el.chapter_title}</ChapterTitle>
                  <ChapterCopy>{el.threshold_description}</ChapterCopy>
                </ChapterTextContainer>
              </ChapterLinkContainer>
              <ChapterState chapterState={el.threshold_color}>
                {el.threshold_label}
              </ChapterState>
            </ChapterListItem>
          ))
        }
      </ChapterList>
    </ChapterContainer>
  )
}

export default ChapterSummary

const ChapterContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  max-inline-size: 620px;
  padding: 30px;
  width: 100%;
  z-index: 1;

  ${mq.desktop} {
    margin-block: 80px;
    padding: 60px;
  }
`

const ChapterList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  ${mq.tablet} {
    margin-block-end: 20px;
    row-gap: 30px;
  }
`

const ChapterListItem = styled.li`
  align-items: flex-start;
  color: ${colors.black};
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  text-decoration: none;

  ${mq.desktop} {
    align-items: center;
  }
`

const ChapterIcon = styled.div`
  margin-inline-end: 10px;
  width: 20px;

  ${mq.desktop} {
    width: 50px;
  }

  img {
    width: inherit;
  }
`

const ChapterTextContainer = styled.div``

const ChapterTitle = styled.p`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 14px;
  line-height: 1.29;

  ${mq.desktop} {
    font-size: 16px;
    line-height: 1.5;
  }
`

const ChapterCopy = styled.p`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.darkGray};

  margin-block-start: 8px;

  ${mq.desktop} {
    font-size: 13px;
    line-height: 1.46;
    max-inline-size: 300px;
    margin-inline: 0;
    margin-block-start: 0;
  }
`

const ChapterLinkContainer = styled.div`
  align-items: flex-start;
  display: flex;
  max-inline-size: 360px;
  margin-inline-end: 20px;

  ${mq.desktop} {
    align-items: center;
    margin-inline-end: 0;
  }
`;

const ChapterState = styled.span`
  background-color: ${props => handleBackgroundColor(props.chapterState)};
  color: ${props => handleFontColor(props.chapterState)};
  font-family: "BuckeyeSans";
  font-size: 12px;
  padding-block: 1px;
  padding-inline: 10px;
  min-inline-size: 80px;
  max-inline-size: 80px;
  text-align: center;

  ${mq.tablet} {
    min-inline-size: 102px;
    padding-block: 4px;
    padding-inline: 11px;
  }
`;
