import React from 'react'
import styled from 'styled-components'
import { mq } from '../../styles/theme'

const WideContainer = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

export default WideContainer

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  max-width: unset;
  padding: 20px 20px;
  margin: auto;
  width: 100%;

  ${mq.tablet} {
    padding: 80px 80px;
    max-width: 1440px;
  }
`
