import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import { colors, mq } from '../../styles/theme'
import OrbImage from '../OrbImage';
import { getResultsListing } from '../../services/questionnaire'

const QuestionnaireResultsListing = ({ heading }) => {

  const [resourceListing, setResourceListing] = useState([])
  const [username, setUsername] = useState(null)

  useEffect(() => {
    getResultsListing().then(res => {
      setResourceListing(res?.resources)
      setUsername(res?.name)
    })
  }, [])

  const goalsArray = list => {
    const goals = []

    list.map(el => {
      return goals.push(el.name)
    })
    return goals.join(' | ')
  }

  return (
    <QuestionnaireResultsListingWrapper>
      <QuestionnaireResultsListingHeading>{username ? `Top Recommendations for ${username}` : heading}</QuestionnaireResultsListingHeading>
      <ResourceCardContainer>
        {resourceListing && resourceListing.map((el, i) => {
          const contentTypeTaxonomy = el.taxonomies.filter(el => el.type === 'resource_content_types')
          const goalTopicsTaxonomy = el.taxonomies.filter(el => el.type !== 'resource_content_types')
          const contentTypeValue = contentTypeTaxonomy[0].name
          const target = el.type === 'internal_resource' ? '_self' : '_blank'
          const link = el.type === 'internal_resource' ? `/resources${el.path}` : el.link

          return (
            <ResourceCardLink href={link} target={target} key={el.nid}>
              <ResourceCard>
                <OrbImageWrapper>
                  <OrbImage type={goalTopicsTaxonomy[0].theme}/>
                </OrbImageWrapper>
                <QuestionnaireResultsListingUpper>
                  <ResourceCardSource>
                    {el.source}
                  </ResourceCardSource>
                  <ResourceCardHeading>{el.title}</ResourceCardHeading>
                </QuestionnaireResultsListingUpper>
                <QuestionnaireResultsListingBottom>
                  <ResourceCardType>{contentTypeValue}</ResourceCardType>
                </QuestionnaireResultsListingBottom>
              </ResourceCard>
            </ResourceCardLink>
          )
        })}
      </ResourceCardContainer>
      <Button type="primary" text="View All Recommendations" link="/resources"/>
    </QuestionnaireResultsListingWrapper>
  )
}

export default QuestionnaireResultsListing

const QuestionnaireResultsListingWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-block: 100px;
  margin-inline: 20px;

  ${mq.desktop} {
    margin-inline: 80px;
  }
`

const QuestionnaireResultsListingHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  margin-block-end: 40px;
  text-align: center;

  ${mq.tablet} {
    font-size: 40px;
  }
`

const QuestionnaireResultsListingUpper = styled.div``

const QuestionnaireResultsListingBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const ResourceCardContainer = styled.div`
  column-gap: 40px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-block-end: 40px;

  row-gap: 40px;
  width: 100%;
  flex-wrap: wrap;
`

const ResourceCard = styled.div`
  background-color: ${colors.white};
  background-size: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 183px;
  padding: 40px 30px;
  overflow: hidden;
  position: relative;

  ${mq.tablet} {
    max-inline-size: 400px;
    margin: auto;
    background-size: 100px;
    min-block-size: 271px;
  }
`

const OrbImageWrapper = styled.div`
  width: 90px;
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(35%, 45%);
  z-index: 1;
  transition: all 0.4s ease-in-out;

  ${mq.tablet} {
    width: 180px;
  }
`

const ResourceCardHeading = styled.h3`
  color: ${colors.black};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 16px;

  ${mq.tablet} {
    font-size: 23px;
  }
`

const ResourceCardLink = styled.a`
  text-decoration: none;
  width: 100%;
  max-inline-size: 100%;
  flex: unset;

  ${mq.tablet} {
    max-inline-size: 400px;
    flex: 1 1 29%;
  }

  &:hover {
    ${OrbImageWrapper} {
      transform: translate(35%, 45%) scale(1.2) rotateZ(15deg);
    }

    ${ResourceCardHeading} {
      color: ${colors.primary}
    }
  }
`

const ResourceCardSource = styled.h6`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 13px;
  margin-block-end: 5px;
`

const ResourceCardType = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 12px;
`

const ResourceCardGoalLabel = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 12px;
`
