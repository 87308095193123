import { colors } from '../styles/theme'
import { slugify } from './globalFunctions';

export const handleBackgroundColor = color => {
  switch (color) {
  case 'green':
    return colors.green;
  case 'blue':
    return colors.blue;
  case 'yellow':
    return colors.yellow;
  case 'orange':
    return colors.orange;
  case 'red':
    return colors.red;
  default:
    return colors.gray;
  }
}

export const handleFontColor = color => {
  switch (color) {
  case 'green':
    return colors.darkGreen;
  case 'blue':
    return colors.darkBlue;
  case 'yellow':
    return colors.darkYellow;
  case 'orange':
    return colors.darkOrange;
  case 'red':
    return colors.darkRed;
  default:
    return colors.darkGray;
  }
}

export const handleThemeColor = color => {
  switch (color) {
  case 'stress':
    return {
      start: '#4100DB',
      middle: '#F5008C',
      end: '#FFCB00'
    };
  case 'mood':
    return {
      start: '#FA6AC5',
      middle: '#86D4F7',
      end: '#F1F1F8'
    };
  case 'trauma':
    return {
      // start: '#F8A44B',
      start: '#F66256',
      middle: '#F8B847',
      end: '#D044C6'
    };
  case 'alcohol':
    return {
      start: '#8676E3',
      middle: '#F88E82',
      end: '#AE4888'
    };
  case 'drugs':
    return {
      start: '#A849F4',
      middle: '#80A5EB',
      end: '#D1C388'
    };
  case 'health':
    return {
      start: '#26686d',
      middle: '#89d972',
      end: '#41b6e5'
    };
  default:
    return {
      start: '#26686d',
      middle: '#89d972',
      end: '#41b6e5'
    };
  }
}
