import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ChapterSummary from '../ChapterSummary'
import { mq, colors } from '../../styles/theme'
import { isLoggedIn } from '../../services/auth';
import { getQuestionnaireResults } from '../../services/questionnaire'

const ResultsMasthead = ({ CopyNameNotProvided, CopyNameProvided, MastheadResultsTitle }) => {

  const [name, setName] = useState('')
  const [chapters, setChapters] = useState([])

  useEffect(() => {
    getQuestionnaireResults().then(el => {
      setName(el.name)
      setChapters(el.chapters)
    })
  }, [])

  return (
    <Container>
      <Cta>
        <CtaHeading>
          {
            name ? <><CtaUser>{name},</CtaUser> here's your results</> : MastheadResultsTitle
          }
        </CtaHeading>
        <CtaCopy>
          {
            name ? CopyNameProvided : CopyNameNotProvided
          }
        </CtaCopy>
        <CtaBtn href={isLoggedIn() ? '/app/dashboard' : '/app/login?from=questionnaire/results'}>{isLoggedIn() ? 'See Your Dashboard' : 'Save Results'}</CtaBtn>
      </Cta>
      <Wave></Wave>
      {
        chapters && <ChapterSummary results={chapters} />
      }
    </Container>
  )
}

export default ResultsMasthead

const Container = styled.section`
  display: flex;
  align-items: center;
  column-gap: 40px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  min-block-size: 535px;
  background: ${colors.mainWaveColor};
  overflow: hidden;

  padding: 40px 20px;

  ${mq.desktop} {
    flex-direction: row;
    flex-wrap: nowrap;
    min-block-size: 750px;
    padding: 0;
  }
`;

const Cta = styled.div`
  margin-inline-start: 0;
  z-index: 2;

  ${mq.desktop} {
    margin-inline-start: 80px;
  }
`

const CtaHeading = styled.h1`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  line-height: 1.04;
  margin-block-end: 20px;
`

const CtaUser = styled.span``

const CtaCopy = styled.p`
  color: ${colors.white};
  max-inline-size: 480px;
`

const CtaBtn = styled.a`
  background: ${colors.white};
  border: 2px solid ${colors.white};
  color: ${colors.darkGreen};
  cursor: pointer;
  display: inline-block;
  margin-block: 30px;
  padding: 13px 23px;
  text-decoration: none;
  text-align: center;
  transition: background .3s ease;
  width: 100%;

  &:hover {
    background: transparent;
    color: ${colors.white};
  }

  ${mq.desktop} {
    margin-block: 20px;
    max-inline-size: 200px;
    text-align: center;
  }
`

const Wave = styled.div`
  position: relative;
  left: 50%;
  &:after {
    content: "";
    position: absolute;
    min-width: 300vw;
    min-height: 300vw;
    background: ${colors.black};
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    bottom: 50%;
    opacity: .1;
    border-radius: 35%;
    animation-duration: 50s;
  }

  @keyframes rotate {
    0% {transform: translate(-50%, 0) rotateZ(0deg);}
    50% {transform: translate(-50%, -2%) rotateZ(180deg);}
    100% {transform: translate(-50%, 0%) rotateZ(360deg);}
  }
`;
