import { getToken, isLoggedIn } from './auth';

const getChapterUrl = `${process.env.DRUPAL_JSONAPI_URL}webform_rest`
const createQuestionnaireUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire/?_format=json`
const getStatusUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire/status`
const getStatusAnonymousUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire/status`
const getQuestionnairePreviewUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire-preview`
const patchChapterUrl = `${process.env.DRUPAL_JSONAPI_URL}chapter`
const requestQuestionnaireResultsUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire/results/masthead?_format=json`
const sendAnonymousDataUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire`
const requestResultsListingUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire/results/resources`
const requestQuoteRowUrl = `${process.env.DRUPAL_JSONAPI_URL}questionnaire/results/quote-row`
const getChapterAnswersUrl = `${process.env.DRUPAL_JSONAPI_URL}webform_rest`

export const fetchChapterInfo = async (chapter, extra_info) => {
  
  const response = await fetch(`${getChapterUrl}/${chapter}/elements`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });

  if (response.ok) {
    const json = await response.json();
    if (json.error) {
      throw new Error(json.error.message);
    }
    return { ...json, ...extra_info };
  }
}

export const getStatus = async () => {
  const token = getToken()
  let response

  const anonymousQuestionnaire = getAnonymousQuestionnaire()

  if (!isLoggedIn()) {
    response = await fetch(`${getStatusAnonymousUrl}/${anonymousQuestionnaire?.sub_id ?? ''}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        timestamp: anonymousQuestionnaire?.timestamp,
        token: anonymousQuestionnaire?.token
      })
    });
  } else {
    response = await fetch(`${getStatusUrl}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
    });
  }

  if (response.ok) {
    const json = await response.json();
    if (json.error) {
      throw new Error(json.error.message);
    }
    return json;
  }
}

export const getQuestionnairePreview = async (questionnaire) => {
  const response = await fetch(`${getQuestionnairePreviewUrl}/${questionnaire}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });

  if (response.ok) {
    const json = await response.json();
    if (json.error) {
      throw new Error(json.error.message);
    }
    return json;
  }
}

export const createQuestionnaire = async (questionnaire) => {
  const token = getToken()

  let headers = new Headers({
    'Content-Type': 'application/json'
  })

  if (token && isLoggedIn()) {
    localStorage.removeItem('anonymous-questionnaire')
    headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    })
  }

  const response = await fetch(`${createQuestionnaireUrl}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      'questionnaire_id': [
        {
          'target_id': questionnaire
        }
      ]
    })
  });

  if (response.ok) {
    const json = await response.json();
    if (json.error) {
      throw new Error(json.error.message);
    }

    if (!isLoggedIn() && json.questionnaire_sub_id && json.token && json.timestamp) {
      saveAnonymousQuestionnaire({
        sub_id: json.questionnaire_sub_id,
        timestamp: json.timestamp,
        token: json.token
      })
    }

    return json;
  }
}

export const submitChapter = async (body) => {
  let submitChapterUrl = `${process.env.DRUPAL_JSONAPI_URL}chapter`
  const token = getToken()
  const anonymousQuestionnaire = getAnonymousQuestionnaire()

  let headers = new Headers({
    'Content-Type': 'application/json'
  })

  if (token && isLoggedIn()) {
    localStorage.removeItem('anonymous-questionnaire')
    headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    })
  } else {
    body = {...body,
      timestamp: anonymousQuestionnaire?.timestamp,
      token: anonymousQuestionnaire?.token
    }

    if (anonymousQuestionnaire) {
      submitChapterUrl += `/${anonymousQuestionnaire.sub_id}`
    } else {
      return false
    }
  }

  const response = await fetch(`${submitChapterUrl}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  });

  if (response.ok) {
    const json = await response.json();
    if (json.error) {
      throw new Error(json.error.message);
    }
    return json;
  }
}

export const patchChapter = async (body, chapter_submission_id) => {

  const token = getToken()
  const anonymousQuestionnaire = getAnonymousQuestionnaire()

  let headers = new Headers({
    'Content-Type': 'application/json'
  })

  if (token && isLoggedIn()) {
    localStorage.removeItem('anonymous-questionnaire')
    headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    })
  } else {
    if (anonymousQuestionnaire) {
      body = {...body,
        timestamp: anonymousQuestionnaire?.timestamp,
        token: anonymousQuestionnaire?.token
      }
    } else {
      return false
    }
  }

  const response = await fetch(`${patchChapterUrl}/${chapter_submission_id}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(body)
  });

  if (response.ok) {
    const json = await response.json();
    if (json.error) {
      throw new Error(json.error.message);
    }
    return json;
  }
}

const saveAnonymousQuestionnaire = json => {
  localStorage.setItem('anonymous-questionnaire', JSON.stringify(json));
  return true;
};

const getAnonymousQuestionnaire = () => {
  let response = localStorage.getItem('anonymous-questionnaire') !== null
    ? JSON.parse(localStorage.getItem('anonymous-questionnaire'))
    : null;

  return response
}

// Questionnaire Results Endpoint -> returns questionnaire masthead data
export const getQuestionnaireResults = async () => {
  const token = getToken()
  if (token && isLoggedIn()) {
    await sendAnonymousData()
    localStorage.removeItem('anonymous-questionnaire')
  }
  const anonymousQuestionnaire = getAnonymousQuestionnaire();

  const method = {
    get: 'GET',
    post: 'POST'
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: !anonymousQuestionnaire ? `Bearer ${token}` : null
  }

  const body = {
    timestamp: anonymousQuestionnaire?.timestamp,
    token: anonymousQuestionnaire?.token,
    questionnaire_sub_id: anonymousQuestionnaire?.sub_id
  }

  const response = await fetch(requestQuestionnaireResultsUrl, {
    method: anonymousQuestionnaire ? method.post : method.get,
    body: anonymousQuestionnaire ? JSON.stringify(body) : null,
    headers
  })

  const data = await response.json();

  return data
}

// Send anonymous data once logged in
const sendAnonymousData = async () => {
  const anonymousData = getAnonymousQuestionnaire()
  const token = getToken()

  if (anonymousData){
    const subId = anonymousData?.sub_id
    const body = {
      timestamp: anonymousData?.timestamp,
      token: anonymousData?.token
    }
    const req = await fetch(`${sendAnonymousDataUrl}/${subId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })

    const res = await req.json();

    return res;
  }
}

// Get results listing on Questionnaire Page (loggedin user or anonymous)
export const getResultsListing = async () => {
  const token = getToken()
  if (token && isLoggedIn()) {
    await sendAnonymousData()
    localStorage.removeItem('anonymous-questionnaire')
  }
  const anonymousQuestionnaire = getAnonymousQuestionnaire();

  const method = {
    get: 'GET',
    post: 'POST'
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: !anonymousQuestionnaire ? `Bearer ${token}` : null
  }

  const body = {
    timestamp: anonymousQuestionnaire?.timestamp,
    token: anonymousQuestionnaire?.token,
    questionnaire_sub_id: anonymousQuestionnaire?.sub_id
  }

  const response = await fetch(requestResultsListingUrl, {
    method: anonymousQuestionnaire ? method.post : method.get,
    body: anonymousQuestionnaire ? JSON.stringify(body) : null,
    headers
  })

  const data = await response.json();

  return data
}


export const getQuoteRow = async () => {
  const token = getToken()

  const anonymousQuestionnaire = getAnonymousQuestionnaire();

  const method = {
    get: 'GET',
    post: 'POST'
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: !anonymousQuestionnaire ? `Bearer ${token}` : null
  }

  const body = {
    timestamp: anonymousQuestionnaire?.timestamp,
    token: anonymousQuestionnaire?.token,
    questionnaire_sub_id: anonymousQuestionnaire?.sub_id
  }

  const response = await fetch(requestQuoteRowUrl, {
    method: anonymousQuestionnaire ? method.post : method.get,
    body: anonymousQuestionnaire ? JSON.stringify(body) : null,
    headers
  })

  const data = await response.json();

  return data
}

export const getChapterAnswers = async (webform_id, uuid) => {
  const token = getToken()
  let response

  if (isLoggedIn() && token) {
    response = await fetch(`${getChapterAnswersUrl}/${webform_id}/submission/${uuid}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
    });

    if (response.ok) {
      const json = await response.json();
      if (json.error) {
        throw new Error(json.error.message);
      }
      return json;
    }
  }

  return false
}
